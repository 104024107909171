// eslint-disable-next-line import/no-anonymous-default-export
export default {
  DASHBOARD: "/",
  ENVIOPAGO: "/miCarrito/envioPago",
  CARRITOCONFIRMAR: "/miCarrito/confirmar",
  MAIN: "/main",
  LOGIN: "/login",
  LOGIN_ADMIN: "/admin/login",
  FIRST_LOGIN_MESSAGE: "/firstlogin",
  CHANGE_PASSWORD: "/changepassword/:nroDoc/:tipoDoc/:email",
  CHANGE_PASSWORD_EMAIL: "/changePasswordEmail/:Email/:Code",
  RESET_PASWORD: "/reestablecer",
  RESET_PASWORD_ADMIN: "/admin/reestablecer",
  RESET_PASWORD_MESSAGE: "/reestablecerpassword/mensaje",
  RESTORE_PASSWORD: "/reestablecerpassword/nuevapassword",
  MIS_PRESUPUESTOS: "/presupuestos",
  RECLAMOS: "/reclamos",
  ACOPIOS: "/acopios",
  NOVEDADES: "/novedades",
  NOVEDAD: "/novedad/:id",
  NUEVO_RECLAMO: "/nuevo-reclamo",
  VENDEDORES: "/vendedores",
  NUEVO_VENDEDOR: "/vendedor/nuevo",
  EDITAR_VENDEDOR: "/vendedor/editar",
  CLIENTES: "/clientes",
  PERFIL: "/cliente/perfil",
  PERFILCLIENTEVENDEDOR: "/cliente/perfilvendedor",
  PERFIL_VENDEDOR: "/vendedor/perfil",
  DETALLES_PRODUCTO: "/detallesproducto",
  PRODUCTOS: "/productos",
  ALL_PRODUCTOS: "/allProductsList",
  FORM_CONTACT: "/formulario_contacto",
  CARRITO: "/miCarrito",
  ERROR: "/error",
  PRODUCTO_SUGERIDO: "/producto-sugerido",
  NUEVO_PRODUCTO_SUGERIDO: "/nuevo-producto-sugerido",
  CONFIGURACION: "/configuracion",
  CUENTA_CORRIENTE: "/cuenta-corriente",
  BONIFICACIONES: "/bonificaciones",
  CONSUL_STEEL: "/consul-steel"
};
