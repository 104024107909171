export const Types= {
    NPS_LOADING: 'NPS_LOADING',
    NPS_INSERT_REQUEST: 'NPS_INSERT_REQUEST',
    NPS_SUCCEEDED: 'NPS_SUCCEEDED',
    NPS_ERROR: 'NPS_ERROR'
};

export const startLoading = () => ({type: Types.NPS_LOADING});
export const requestInsertNps = (npsData) => ({type: Types.NPS_INSERT_REQUEST, npsData});
export const responseSucceeded = ()  => ({type: Types.NPS_SUCCEEDED });
export const npsError = (error) => ({type: Types.NPS_ERROR, error});