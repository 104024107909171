import {call, put, takeEvery, fork} from 'redux-saga/effects';
import * as actions from '../actions/nps';
import nps from '../services/nps';

function* insertNps({npsData}) {
    yield put(actions.startLoading());
    try {
        const res = yield call(nps.insertHistorico, npsData);
        if(res){
            yield put(actions.responseSucceeded());
        }
    } catch (error) {
        yield put(actions.npsError(error));
    }
}

function*  watchInsertRequest() {
    yield takeEvery(actions.Types.NPS_INSERT_REQUEST, insertNps);
}

const npsSagas = [
    fork(watchInsertRequest)
]

export default npsSagas;