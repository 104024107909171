const SWOW_POLICY = 'showPolicy';
const SWOW_NPS = 'showNps';

export default class MyUserService {
    static getMyUser() {
        return JSON.parse(window.localStorage.getItem('LocalSession'));
    }
    static getMyGroup() {
        return JSON.parse(window.localStorage.getItem('grupoId'));
    }
    static setMostrarPolitica(mostrarPoliticasReclamo) {
        localStorage.setItem(SWOW_POLICY, mostrarPoliticasReclamo);
    }
    static setShowNps(showNps) {
        localStorage.setItem(SWOW_NPS, showNps);
    }
    static showPolitica() {
        return localStorage.getItem(SWOW_POLICY);
    }
    static showNps() {
        return localStorage.getItem(SWOW_NPS);
    }
}