import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { requestUltimosPresupuestos } from "../../actions/presupuesto";
import { requestKilos } from "../../actions/params";
import Dolar from "../../routes/dolar";
import Pedido from "../misPresupuestos/pedido";
import ImagenCabecera from "../../routes/imagenCabecera";
import { Container } from "react-bootstrap";
import { Grid, Button } from "@material-ui/core";
import rutas from "../../routes/constants/routes";
import MyUserService from "../../services/myUser";
import { isAllowed } from "../../shared/utils/permissions";
import "../../../node_modules/react-vis/dist/style.css";
import { VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, FlexibleXYPlot } from "react-vis";
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import "dayjs/locale/es";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core";
import PoliticaReclamo from "./popUpPoliticaReclamo";
import NPSRating from '../nps/nps';

const styles = makeStyles(() => ({
  multipleSelect: {
    "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
      backgroundColor: "rgb(227, 0, 11);",
      color: "white",
    },
  },
}));

const Dashboard = (props) => {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDialogPolitica, setOpenDialogPolitica] = useState(JSON.parse(MyUserService.showPolitica()));  
  const [showNps, setShowNps] = useState(JSON.parse(MyUserService.showNps()));  

  const [category, setCategory] = useState([]);
  const [dateFrom, setDateFrom] = useState(dayjs(moment()));
  const [dateTo, setDateTo] = useState(dayjs(moment()));
  const [errorUI, seterrorUI] = useState({
    category: "",
    date: "",
  });

  const handleDateFromChange = (newDate) => {
    setDateFrom(newDate);
  };

  const handleDateToChange = (newDate) => {
    setDateTo(newDate);
  };

  const myUser = MyUserService.getMyUser();
  const clienteSeleccionado = JSON.parse(
    window.localStorage.getItem("selectedClient")
  );
  useEffect(() => {
    if (isAllowed(15) && isAllowed(20)) {
      dispatch(requestUltimosPresupuestos(myUser?.clienteId));
      dispatch(requestKilos(myUser?.clienteId, dateFrom, dateTo, category));
    }
  }, []);

  const getKilosRequest = () => {
    if (isAllowed(15) && isAllowed(20)) {
      dispatch(requestKilos(myUser?.clienteId, dateFrom, dateTo, category));
    }
  };

  const handleUpdateDashboard = () => {
    if (!handleValidation()) {
      getKilosRequest();
    }
  };

  const handleValidation = () => {
    let newErrors = {
      category: "",
      date: "",
    };
    let haveError = false;
    if (moment(dateFrom).isBefore(dateTo)) {
      newErrors = {
        ...newErrors,
        date: "Intervalo incorrecto; la fecha de inicio no puede ser mayor a la fecha de finalización",
      };
      haveError = true;
    }
    // if (dateTo.diff(dateFrom, "months") < 1) {
    //   newErrors = {
    //     ...newErrors,
    //     date: "El intervalo debe ser mayor a 2 meses",
    //   };
    //   haveError = true;
    // }

    if (category.length === 0) {
      newErrors = {
        ...newErrors,
        category: "Debe elegir al menos una sub-categoria",
      };
      haveError = true;
    }

    if (dateTo.diff(dateFrom, "months") > 12) {
      newErrors = {
        ...newErrors,
        date: "El intervalo no debe ser mayor a 12 meses",
      };
      haveError = true;
    }

    seterrorUI(newErrors);
    return haveError;
  };

  const handleChangeCategory = ({ target }) => {
    setCategory(target.value);
  };

  const { ultimosPresupuestos, loading, gruposProductos } = props;
  const pedidos = ultimosPresupuestos;

  const colores = {
    1: "#01B8AA",
    2: "#374649",
    3: "#3599B8",
    4: "#C39FBB",
    5: "#FFC0CB",
    6: "#A52A2A",
    7: "#32a852",
    8: "#3285a8",
    9: "#3c32a8",
    10: "#a83295",
    11: "#a83248",
    12: "#3b040e",
    13: "#e0b12d",
    14: "#e0e02d",
    15: "#abe02d",
    16: "#2de0a2",
    17: "#E3000B",
    18: "#E3000B",
    19: "#E3000B",
    20: "#E3000B"
  };

  let respuesta = [];

  const coloresCategorias = [];

  const handleClick = () => {
    history.push(`${rutas.MIS_PRESUPUESTOS}`);
  };

  return (
    <>
      <ImagenCabecera> </ImagenCabecera>
      <Dolar> </Dolar>

      <div className="container">
        <div className="justify-content-center">
          {isAllowed(20) && (
            <Container>
              <Grid className="row">
                <div
                  className="d-flex justify-content-between flex-wrap align-items-center"
                  style={{
                    marginBottom: "0.600rem",
                    paddingRight: "3rem",
                    paddingLeft: "21px",
                  }}
                >
                  <h1 className="fw-bolder" style={{ margin: "1rem 0" }}>
                    MIS ÚLTIMAS OPERACIONES
                  </h1>
                </div>
              </Grid>

              {loading && !Array.isArray(pedidos) ? (
                <p className="text-center" style={{ fontSize: "1.5rem" }}>
                  Cargando Operaciones...
                </p>
              ) : (
                <>
                  <Grid container alignItems="start">
                    <Grid item xs={12}>
                      <Grid className="row">
                        {
                          <Grid container className="d-block-sm">
                            {pedidos?.map((pedido) => (
                              <Pedido key={pedido.id} data={pedido} />
                            ))}
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 5,
                        }}
                      >
                        {pedidos?.length > 3 && (
                          <Button
                            variant="outlined"
                            style={{
                              borderColor: "black",
                              borderRadius: "0.75rem",
                              fontSize: "1rem",
                              fontWeight: "bold",
                              width: "12rem",
                              marginTop: "1.75rem",
                            }}
                            onClick={() => handleClick()}
                          >
                            <span>
                              Ver Más <b>Operaciones</b>
                            </span>
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Container>
          )}
        </div>
      </div>

      {(
        myUser?.clienteId
          ? myUser?.clienteId
          : clienteSeleccionado
          ? clienteSeleccionado[clienteSeleccionado.length - 1]?.id
          : null
      ) ? (
        <>
          <div className="d-flex justify-content-center">
            <hr style={{ width: "91.6%", color: "black", marginTop: "0" }}></hr>
          </div>
          <Container>
            <Grid className="row">
              <div
                className="d-flex justify-content-between flex-wrap align-items-center"
                style={{
                  marginBottom: "0.600rem",
                  paddingRight: "3rem",
                  paddingLeft: "21px",
                }}
              >
                <h1 className="fw-bolder" style={{ margin: "1rem 0" }}>
                  DASHBOARD DE SEGUIMIENTO
                </h1>
              </div>
            </Grid>
          </Container>
          <Container>
            <div
              style={{
                marginBottom: "0.600rem",
                paddingRight: "3rem",
                paddingLeft: "21px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <LocalizationProvider
                          adapterLocale="es"
                          dateAdapter={AdapterDayjs}
                        >
                          <MobileDatePicker
                            label="Desde"
                            inputFormat="DD/MM/YYYY"
                            value={dateFrom}
                            onChange={handleDateFromChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(errorUI?.date)}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <LocalizationProvider
                          adapterLocale="es"
                          dateAdapter={AdapterDayjs}
                        >
                          <MobileDatePicker
                            label="Hasta"
                            inputFormat="DD/MM/YYYY"
                            value={dateTo}
                            onChange={handleDateToChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(errorUI?.date)}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {errorUI?.date !== "" && (
                        <FormHelperText error={true}>
                          {errorUI?.date}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="categorias-label">Grupo</InputLabel>
                        <Select
                          multiple
                          labelId="categorias-label"
                          id="categorias"
                          value={category}
                          label="Categorias"
                          className={classes.multipleSelect}
                          onChange={handleChangeCategory}
                          error={Boolean(errorUI?.category)}
                        >
                          {gruposProductos?.map((g) => (
                            <MenuItem value={g.id}>{g.descripcion}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {errorUI?.category !== "" && (
                        <FormHelperText error={true}>
                          {errorUI?.category}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className="my-3"
                    style={{ backgroundColor: colores[7], color: "#fff" }}
                    variant="contained"
                    onClick={handleUpdateDashboard}
                  >
                    Actualizar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>

          <div className="container">
            <div className="justify-content-center">
              <div
                style={{
                  border: "0.1rem solid black",
                  margin: "1rem",
                  padding: "0.5rem",
                }}
              >
                <h2 style={{ fontWeight: "bolder" }} className="text-center">
                  INFORME DE ÚLTIMAS OPERACIONES
                </h2>
                <p style={{ fontWeight: "bolder" }} className="text-center">
                  (Expresado toneladas)
                </p>
                <div
                  style={{
                    flexDirection: "row",
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={1}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 8,
                        }}
                      >
                        Marca:
                      </div>
                    </Grid>
                    {props?.kilos?.$values.map((datos) => {
                      let numberColor = 1;
                      return datos?.datos?.$values.map((value) => {
                        const isExist = coloresCategorias.find(
                          (c) => c.categoryId === value.grupo
                        );
                        if (!isExist) {
                          coloresCategorias.push({
                            categoryId: value.grupo,
                            color: colores[numberColor],
                          });
                          numberColor++;
                          const result = (
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <span style={{ color: colores[numberColor] }}>
                                  {" "}
                                  █{" "}
                                </span>
                                {`${value.grupo}`}
                              </span>
                            </Grid>
                          );
                          respuesta.push(result);
                          return result;
                        }
                        return null;
                      });
                    })}
                  </Grid>
                </div>

                <FlexibleXYPlot xType="ordinal" height={500}>
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis />
                  {props?.kilos?.$values.map((datos) =>
                    datos?.datos?.$values.map((value) => (
                      <VerticalBarSeries
                        key={datos?.rango + value?.kilos + value.grupo}
                        color={
                          coloresCategorias.find(
                            (c) => c.categoryId === value.grupo
                          )?.color
                        }
                        data={[{ x: datos?.rango, y: value.kilos }]}
                      />
                    ))
                  )}
                </FlexibleXYPlot>
              </div>

              <div
                style={{
                  border: "0.1rem solid black",
                  margin: "1rem",
                  padding: "0.5rem",
                }}
              >
                <h2 style={{ fontWeight: "bolder" }} className="text-center">
                  INFORME DE VENTAS
                </h2>
                <p style={{ fontWeight: "bolder" }} className="text-center">
                  (Expresado en millones de $ argentinos)
                </p>
                <div
                  style={{
                    flexDirection: "row",
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid xs={1}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: 8,
                        }}
                      >
                        Marca:
                      </div>
                    </Grid>
                    {props?.kilos?.$values.map((datos) => {
                      let numberColor = 1;
                      return datos?.datos?.$values.map((value) => {
                        const isExist = coloresCategorias.find(
                          (c) => c.categoryId === value.grupo
                        );
                        if (!isExist) {
                          coloresCategorias.push({
                            categoryId: value.grupo,
                            color: colores[numberColor],
                          });
                          numberColor++;
                          const result = (
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <span style={{ color: colores[numberColor] }}>
                                  {" "}
                                  █{" "}
                                </span>
                                {`${value.grupo}`}
                              </span>
                            </Grid>
                          );
                          respuesta.push(result);
                          return result;
                        }
                        return null;
                      });
                    })}
                  </Grid>
                </div>

                <FlexibleXYPlot xType="ordinal" height={500}>
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis />
                  {props?.kilos?.$values.map((datos) =>
                    datos?.datos?.$values.map((value) => (
                      <VerticalBarSeries
                        key={datos?.rango + value?.kilos + value.grupo}
                        color={
                          coloresCategorias.find(
                            (c) => c.categoryId === value.grupo
                          )?.color
                        }
                        data={[{ x: datos?.rango, y: value.gasto }]}
                      />
                    ))
                  )}
                </FlexibleXYPlot>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {/* POPUP Politica de Reclamos */}
      {openDialogPolitica && (
        <PoliticaReclamo setOpenDialogPolitica={setOpenDialogPolitica}></PoliticaReclamo>
      )};

      {showNps && (
        <NPSRating setShowNps={setShowNps} />
      )};
    </>
  );
};

export default connect(
  (state) => ({
    ultimosPresupuestos: state.presupuestos.ultimosPresupuestos,
    loading: state.presupuestos.loading,
    kilos: state.params.kilos,
    gruposProductos: state.params.grupos,
  }),
  (dispatch) => ({
    requestUltimosPresupuestos: (userId) =>
      dispatch(requestUltimosPresupuestos(userId)),
    requestKilos: (clienteId) => dispatch(requestKilos(clienteId)),
  })
)(Dashboard);
