import { useFormik } from "formik";
import { connect } from 'react-redux';
import { requestResetPassword } from '../../actions/auth';
import * as Yup from "yup";
import LockIcon from '@mui/icons-material/Lock';

const ResetPassword = (props: any) => {

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().required("El email es obligatorio")
        }),
            onSubmit: (data) => {
                props.requestResetPassword(data);
            }
    })

    return (
        <>
        <div>
            <div className="d-grid gap-2 col-12 col-md-5 mx-auto" style={{paddingTop:"2rem", paddingBottom:"4rem"}}> 

            <div className="container">
                <div className="d-flex flex-row bd-highlight" style={{justifyContent:"center"}}>
                <div className="mb-3" >
                <div style={{height: '3.438rem', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:"3rem" }}>
                            <div style={{width: "45px", height: "45px", background: "#E3000B", borderRadius: "100px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <LockIcon style={{fill: "white"}}></LockIcon>
                            </div>
                        </div>
                        <h5 className="text-center" style={{marginTop:"1rem",marginBottom:"1.875rem"}}>Recuperar Contraseña</h5>
                </div>
                </div>
            </div>

            <form className="container" style={{marginBottom:"2.5rem"}} onSubmit={formik.handleSubmit}>
                    <div>
                        <div className="mb-3">
                        <label htmlFor="email" className="form-label" style={{color:"gray",fontWeight:600}}>Email</label>
                        <input type="email" onChange={formik.handleChange} error={formik.errors.email} name="email" id="email" 
                            className="form-control" style={{borderColor:"black",padding:"0.625rem"}}></input>
                        {formik.touched.email && formik.errors.email ? (
                                <div className='text-danger'>{formik.errors.email}</div>
                                ): null}
                        </div>
                    <div className="mb-3">
                    </div>
                    <div style={{maxWidth:"100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <button className={`btn btn-danger ${props.loading ? "disabled" : ""}`} style={{backgroundColor: "#E3000B", padding: "0.500rem 0.625rem", margin:"1rem 0rem", width:"100%"}} type="submit">Recuperar contraseña</button>
                    </div>
                    {
                        props?.successPasswordReset &&
                        <div className="alert alert-info" role="alert">
                            Por favor verifique su casilla de email para recuperar la contraseña
                        </div>
                    }
                    {
                        props?.errorPasswordReset &&
                        <div className="alert alert-danger" role="alert">
                            { props.error.response.data.message }
                        </div>
                    }
                    
                </div>
            </form>
            
            </div>
        </div>
        </>
    );
}

export default connect(
    (state:any) => ({
        successPasswordReset: state.auth.successPasswordReset,
        loading: state.auth.loading,
        errorPasswordReset: state.auth.errorPasswordReset,
        error: state.auth.error
    }),
    dispatch => ({
        requestResetPassword: (data: any) => dispatch(requestResetPassword(data))
    })
)(ResetPassword);