import { combineReducers } from "redux";

import users from "./users";
import params from "./params";
import vendedor from "./vendedor";
import clientes from "./cliente";
import presupuestos from "./presupuesto";
import auth from "./auth";
import producto from "./producto";
import suggestedProduct from "./suggestedProduct";
import reclamos from "./reclamos";
import configuracion from "./configuracion";
import acopios from "./acopios";
import novedades from "./novedades";
import cuentacorriente from "./cuentacorriente";
import consulsteel from "./consulSteel";
import nps from "./nps";

export default combineReducers({
  auth,
  params,
  users,
  vendedor,
  clientes,
  presupuestos,
  producto,
  suggestedProduct,
  reclamos,
  configuracion,
  acopios,
  novedades,
  cuentacorriente,
  consulsteel,
  nps
});
