import { connect } from "react-redux";
import colores from "../../shared/constants/styles";
import { requestDownloadFile } from "../../actions/presupuesto";
import SendIcon from "@mui/icons-material/Send";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";

interface IEstadoDetalleProps {
    presupuestoDetalle: any;
    onSetPopUp: () => void;
}

const EstadoDetalle = (props: IEstadoDetalleProps) => {

    return (
        <div className="container" style={{ width: "100%" }}>
                {props.presupuestoDetalle?.estadoPresupuesto === "Cancelado" && (
                <div className="alert alert-danger text-center" role="alert">
                    PRESUPUESTO CANCELADO
                </div>
            )}
                <div style={{
                        backgroundColor: colores.grisClaro,
                        paddingTop: "1.20rem",
                        paddingBottom: "1.20rem",
                        borderRadius: "15px 15px 0 0",
                }}>
                    <button
                        onClick={() => props.onSetPopUp()}
                        className="float-end"
                        style={{
                            backgroundColor: colores.grisClaro,
                            position: "absolute",
                            right: "100px",
                            border: "none",
                            cursor: "pointer"
                        }}
                    >X
                    </button>
                    <h4 style={{ fontWeight: "lighter" , marginLeft: "1vw"}}>
                        ESTADO
                    </h4>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "white",
                    }}>
                <div
                    className="row row-cols-1 row-cols-sm-4 row-cols-md-6"
                    style={{ width: "80%" }}>
                    <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                      style={
                        props.presupuestoDetalle?.estadoPresupuesto === "Enviado"
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "rgba(53,186,32,0.62)",
                              borderRadius: "6.25rem",
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }
                      }
                    >
                      <SendIcon
                        fontSize="large"
                        sx={
                            props.presupuestoDetalle?.estadoPresupuesto === "Enviado"
                            ? {
                                color: "rgba(53,186,32,0.62)",
                                marginBottom: "0.625rem",
                              }
                            : {
                                color: colores.grisOscuro,
                                marginBottom: "0.625rem",
                              }
                        }
                      ></SendIcon>
                      <p>Enviado</p>
                    </div>
                  </div>
                  <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                      style={
                        props.presupuestoDetalle?.estadoPresupuesto === "Comercial"
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "rgba(53,186,32,0.62)",
                              borderRadius: "6.25rem",
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }
                      }
                    >
                      <PersonPinIcon
                        fontSize="large"
                        sx={
                            props.presupuestoDetalle?.estadoPresupuesto === "Comercial"
                            ? {
                                color: "rgba(53,186,32,0.62)",
                                marginBottom: "0.625rem",
                              }
                            : {
                                color: colores.grisOscuro,
                                marginBottom: "0.625rem",
                              }
                        }
                      ></PersonPinIcon>
                      <p>Comercial</p>
                    </div>
                  </div>
                  <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                      style={
                        props.presupuestoDetalle?.estadoPresupuesto === "Finanzas"
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "rgba(53,186,32,0.62)",
                              borderRadius: "6.25rem",
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }
                      }
                    >
                      <PersonSearchIcon
                        fontSize="large"
                        sx={
                            props.presupuestoDetalle?.estadoPresupuesto === "Finanzas"
                            ? {
                                color: "rgba(53,186,32,0.62)",
                                marginBottom: "0.625rem",
                              }
                            : {
                                color: colores.grisOscuro,
                                marginBottom: "0.625rem",
                              }
                        }
                      ></PersonSearchIcon>
                      <p>Finanzas</p>
                    </div>
                  </div>
                  <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                      style={
                        props.presupuestoDetalle?.estadoPresupuesto === "Preparación"
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "rgba(53,186,32,0.62)",
                              borderRadius: "6.25rem",
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }
                      }
                    >
                      <Inventory2Icon
                        fontSize="large"
                        sx={
                            props.presupuestoDetalle?.estadoPresupuesto === "Preparación"
                            ? {
                                color: "rgba(53,186,32,0.62)",
                                marginBottom: "0.625rem",
                              }
                            : {
                                color: colores.grisOscuro,
                                marginBottom: "0.625rem",
                              }
                        }
                      ></Inventory2Icon>
                      <p>Preparación</p>
                    </div>
                  </div>
                  <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                      style={
                        props.presupuestoDetalle?.estadoPresupuesto === "En tránsito"
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "rgba(53,186,32,0.62)",
                              borderRadius: "6.25rem",
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }
                      }
                    >
                      <LocalShippingIcon
                        fontSize="100px"
                        sx={
                            props.presupuestoDetalle?.estadoPresupuesto === "En tránsito"
                            ? {
                                fontSize: 39,
                                color: "rgba(53,186,32,0.62)",
                                marginBottom: "0.32rem",
                              }
                            : {
                                fontSize: 39,
                                color: colores.grisOscuro,
                                marginBottom: "0.32rem",
                              }
                        }
                      ></LocalShippingIcon>
                      <p>En tránsito</p>
                    </div>
                  </div>
                  <div className="col" style={{ padding: "1.25rem" }}>
                    <div
                      style={
                        props.presupuestoDetalle?.estadoPresupuesto === "Entregado"
                          ? {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              color: "rgba(53,186,32,0.62)",
                              borderRadius: "6.25rem",
                            }
                          : {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }
                      }
                    >
                      <CheckCircleIcon
                        fontSize="large"
                        sx={
                            props.presupuestoDetalle?.estadoPresupuesto === "Entregado"
                            ? {
                                color: "rgba(53,186,32,0.62)",
                                marginBottom: "0.625rem",
                              }
                            : {
                                color: colores.grisOscuro,
                                marginBottom: "0.625rem",
                              }
                        }
                      ></CheckCircleIcon>
                      <p>Entregado</p>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    )
}

export default connect(
    (state: any) => ({
        file: state.presupuestos.file,
        successDownload: state.presupuestos.successDownload,
    }),
    (dispatch) => ({
        requestDownloadFile: (numero: any, ruta: any) => dispatch(requestDownloadFile(numero, ruta)),
    })
)(EstadoDetalle);
