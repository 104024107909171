import { Types } from '../actions/nps';

export default function npsReducer(state = {loading: false, success: false, error: false}, action) {
    switch (action.type) {
        case Types.NPS_INSERT_REQUEST:
            return {...state, loading: true }
        case Types.NPS_SUCCEEDED:
            return {...state, success: true };
        case Types.NPS_ERROR:
            return {...state, error: action.error, loading: false};
        default:
            return state;
    }
}