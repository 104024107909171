import axios from 'axios'
import MyUserService from './myUser';

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/auth/`


export default class auth {

    static async login(user) {
        const response = await axios.post(`${ENDPOINT}login`, user)
                        .then(response => {
                            return response
                        })
        localStorage.setItem('LocalSession', JSON.stringify(response.data));
        localStorage.setItem('expirationtime', JSON.stringify(response.data.shoopExpirationTime));
        // localStorage.setItem('showPolicy', JSON.stringify(response.data.mostrarPoliticasReclamo));
        MyUserService.setMostrarPolitica(response.data.mostrarPoliticasReclamo);
        MyUserService.setShowNps(response.data.showNps);

        return response.data
    }

    static async loginAdmin(user) {
        const response = await axios.post(`${ENDPOINT}loginAdmin`, user)
                        .then(response => {
                            return response
                        })
        localStorage.setItem('LocalSession', JSON.stringify(response.data));
        return response.data
    }

    // servicio para enviar mail
    static async resetPassword(data) {
        const respuesta = await axios.post(`${ENDPOINT}forgotpassword`,data)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async changePasswordEmail(data) {
        const respuesta = await axios.post(`${ENDPOINT}resetpassword`,data)
        .then(response => {
            return response
        })
        return respuesta.data
    }

    static async changePassword(data) {
        const respuesta = await axios.post(`${ENDPOINT}changepassword`,data)
        .then(response => {
            return response
        })
        return respuesta.data
    }
}