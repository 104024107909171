import axios from 'axios'

const { REACT_APP_ENDPOINT } = process.env;
const ENDPOINT = `${REACT_APP_ENDPOINT}/api/nps/`


export default class nps {
    static async insertHistorico(data) {
        const respuesta = await axios.post(`${ENDPOINT}insertHistorico`,data)
        .then(response => {
            return response
        })
        return respuesta.data
    }
}