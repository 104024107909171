import { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import MyUserService from "../../services/myUser";
import { requestInsertNps } from '../../actions/nps'
import { select } from "redux-saga/effects";
import CloseIcon from "@mui/icons-material/Close"; 

const colors = [
    "#ff3b30", 
    "#ff5230",
    "#ff6930",
    "#ff8030",
    "#ff9730",
    "#ffae30",
    "#ffc530",
    "#ffdc30",
    "#c0e440", 
    "#70e000", 
];

interface NpsProps {
    setShowNps: (openDialog: boolean)=> void;
    success: boolean;
}

const NPSRating= (props: NpsProps) => {
    const dispatch = useDispatch();
    
    const [selected, setSelected] = useState<number | null>(null);
    const handleEnviar= () => {
        let request = {ClienteId: MyUserService.getMyUser().clienteId, ContactoId: MyUserService.getMyUser().contactoId, Punto: selected}
        dispatch(requestInsertNps(request)); 
    }
    const handleClose= () => {
        let request = {ClienteId: MyUserService.getMyUser().clienteId, ContactoId: MyUserService.getMyUser().contactoId, Punto: null}
        dispatch(requestInsertNps(request));
    }

    useEffect(() => {
        debugger
        if (props.success) {
            MyUserService.setShowNps(false);
            props.setShowNps(false);
        }
    }, [props.success]);

    return (
        <Box
            sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                bgcolor: "white",
                boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
                padding: "10px 0",
                zIndex: 1000,
            }}
        >
            <IconButton
                onClick={() => handleClose()}
                sx={{
                position: "absolute",
                top: 5,
                right: 10,
                color: "black",
                }}
            >
                <CloseIcon />
            </IconButton>
            <Grid container direction="column" alignItems="center" spacing={1}>
                <Grid item>
                <Typography textAlign="center" >
                    ¿Cuál es la probabilidad de recomendar Portal Ventas a otra empresa?
                </Typography>
                </Grid>

                <Grid item container alignItems="center" spacing={1} justifyContent="center">
                    <Grid item>
                        <Typography variant="body2" fontWeight="bold" color="textSecondary">
                        Poco recomendable
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Stack direction="row" spacing={1}>
                        {colors.map((color, index) => {
                            const value = index + 1;
                            return (
                            <Button
                                key={value}
                                variant={selected === value ? "contained" : "outlined"}
                                onClick={() => setSelected(value)}
                                sx={{
                                    bgcolor: selected === value ? color : "transparent",
                                    color: selected === value ? "white" : "black",
                                    borderColor: color,
                                    "&:hover": { bgcolor: color, color: "white" },
                                    minWidth: 40,
                                    height: 40,
                                    fontWeight: "bold",
                                }}
                            >
                                {value}
                            </Button>
                            );
                        })}
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Typography variant="body2" fontWeight="bold" color="textSecondary">
                            Muy recomendable
                        </Typography>
                    </Grid>
                    {selected && 
                        <Button 
                            variant="outlined" 
                            onClick={handleEnviar} 
                            color="error"
                            sx={{
                                marginLeft: "2vh"
                            }}
                        >
                            Enviar
                        </Button>
                    }       
                </Grid>
            </Grid>
        </Box>
    );
};

export default connect
(
    (state: any) => ({
        success: state.nps.success
    }),
    dispatch => ({
        requestInsertNps: (request: any)=> dispatch(requestInsertNps(request))
    })
)(NPSRating);